import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Card from '@mui/material/Card';

// custom components
import { Layout } from '../../components/layout/CatalogLanding';
import StyledBox from '../../components/controls/StyledBox';
import Content from '../../custom/segments/Content';
import Gallery from '../../custom/segments/Gallery';
import Downloads from '../../custom/segments/Downloads';
import Newsletter from '../../custom/segments/NewsletterSimple';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

export const query = graphql`
  query ($id: String) {
    mycatalog: datoCmsCatalog(id: { eq: $id }) {
      title
      teaser
      content
      footer
      coverImage {
        gatsbyImageData(width: 1600)
        alt
      }
      gallery {
        alt
        basename
        fixed(width: 250) {
          src
        }
        gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.3)
      }
      downloads {
        title
        basename
        url
        customData
        size
        format
        filename
        alt
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function CatalogPage({ data, pageContext }) {
  const { mycatalog, site } = data;
  const { catalogType, catalog } = pageContext;
  const coverHeader = mycatalog.title;

  const showNewsletter = config.segments.newsletter.enabled;

  const galleryImages = nodesUtils.getGalleryImages(mycatalog.gallery);
  const hasContent = mycatalog && (mycatalog.teaser || mycatalog.content);
  const pageParams = {
    title: coverHeader,
    cover: { ...mycatalog.coverImage },
  };

  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <HelmetDatoCms seo={mycatalog.seo} favicon={site.favicon} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -8,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {hasContent && (
          <StyledBox py={{ xs: 3, md: 6 }}>
            <Content teaser={mycatalog.teaser} body={mycatalog.content} footer={mycatalog.footer} />
          </StyledBox>
        )}
        <Downloads header={'Downloads'} downloads={mycatalog.downloads} />
        <Gallery header={'Bildergalerie'} images={galleryImages} />
        {showNewsletter && <Newsletter />}
      </Card>
    </Layout>
  );
}

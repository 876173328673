import React from 'react';
import Header from '../../custom/segments/HeaderCatalog';
import Footer from '../../custom/segments/Footer';

export function Layout({ catalogType, catalogId, pageParams, children }) {
  return (
    <div>
      <Header
        catalogType={catalogType}
        catalogId={catalogId}
        pageParams={pageParams}
        showSearch={true}
      />
      {children}
      <Footer />
    </div>
  );
}
